import styled from 'styled-components';
import { useEffect } from 'react';
import palette from '../../styles/palette';
import useConversion from '../../useConversion';
import WeeklyData from './WeeklyData';

const Container = styled.div`
  display: flex;
  gap: 5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.div`
  display: flex;
  padding: 4px 24px;
  color: ${palette.textBlack};
  font-size: 18px;
  font-weight: 700;
`;

const ContentList = styled.div`
  padding-left: 16px;
`;

const Row = styled.li`
  display: flex;
  margin: 12px 4px;
  padding: 4px;

  > span {
    min-width: 15em;
  }
`;

const RowContent = styled.div`
  display: flex;
`;

export default function MenuData({ menu }: { menu: string; }) {
  const {
    loadConversion,
    filteredGuestCount,
    totalGuestCount,
    totalUserCount,
    totalConsultationCount,
    totalInquiryCount,
    longInquiryCount,
    inquiryGuestCount,
    inquiryUserCount,
    inquiryConversion,
    signupConversion,
    criminalPossibilityInquiryCount,
    legalPossibilityInquiryCount,
    legalMethodInquiryCount,
    legalValidityInquiryCount,
    proceduralInquiryCount,
    otherInquiryCount,
    nothingInquiryCount,
    criminalPossibilityInquiryRatio,
    legalPossibilityInquiryRatio,
    legalMethodInquiryRatio,
    legalValidityInquiryRatio,
    proceduralInquiryRatio,
    otherInquiryRatio,
  } = useConversion();

  useEffect(() => {
    loadConversion();
  }, [loadConversion]);

  const longInquiryRatio = ((longInquiryCount / totalInquiryCount) * 100).toFixed(2);

  return (
    menu !== '전체'
      ? (
        <WeeklyData menu={menu} />
      )
      : (
        <Container>
          <Content>
            <ContentTitle>전체</ContentTitle>
            <ContentList>
              <Row>
                <span>전체 방문자:</span>
                <RowContent>{totalGuestCount}</RowContent>
              </Row>
              <Row>
                <span>전체 회원가입 수:</span>
                <RowContent>{totalUserCount}</RowContent>
              </Row>
              <Row>
                <span>전체 상담건수:</span>
                <RowContent>{totalConsultationCount}</RowContent>
              </Row>
              <Row>
                <span>150자 이상 문의수 / 전체 문의수:</span>
                <RowContent>
                  <span>
                    {longInquiryRatio}
                    % (
                    {longInquiryCount}
                    {' '}
                    /
                    {totalInquiryCount}
                    )
                  </span>
                </RowContent>
              </Row>
              <Row>
                <span>방문자:</span>
                <RowContent>{filteredGuestCount}</RowContent>
              </Row>
              <Row>
                <span>상담을 진행한 방문자:</span>
                <RowContent>
                  {inquiryGuestCount}
                  {' '}
                  (
                  {inquiryConversion}
                  %)
                </RowContent>
              </Row>
              <Row>
                <span>상담을 진행한 사용자(회원가입):</span>
                <RowContent>
                  {inquiryUserCount}
                  {' '}
                  (
                  {signupConversion}
                  %)
                </RowContent>
              </Row>
            </ContentList>
          </Content>
          <Content>
            <ContentTitle>Inquiry type별 상담 건수</ContentTitle>
            <ContentList>
              <Row>
                <span>형사처벌 가능성 문의:</span>
                <RowContent>
                  {criminalPossibilityInquiryCount}
                  {' '}
                  (
                  {criminalPossibilityInquiryRatio}
                  %)
                </RowContent>
              </Row>
              <Row>
                <span>법적 대응 가능성 문의:</span>
                <RowContent>
                  {legalPossibilityInquiryCount}
                  {' '}
                  (
                  {legalPossibilityInquiryRatio}
                  %)
                </RowContent>
              </Row>
              <Row>
                <span>법적 대응 방법 문의:</span>
                <RowContent>
                  {legalMethodInquiryCount}
                  {' '}
                  (
                  {legalMethodInquiryRatio}
                  %)
                </RowContent>
              </Row>
              <Row>
                <span>주장의 법적 타당성 문의:</span>
                <RowContent>
                  {legalValidityInquiryCount}
                  {' '}
                  (
                  {legalValidityInquiryRatio}
                  %)
                </RowContent>
              </Row>
              <Row>
                <span>절차에 대한 궁금증 문의:</span>
                <RowContent>
                  {proceduralInquiryCount}
                  {' '}
                  (
                  {proceduralInquiryRatio}
                  %)
                </RowContent>
              </Row>
              <Row>
                <span>기타 문의:</span>
                <RowContent>
                  {otherInquiryCount}
                  {' '}
                  (
                  {otherInquiryRatio}
                  %)
                </RowContent>
              </Row>
              <Row>
                <span>해당 없음:</span>
                <RowContent>{nothingInquiryCount}</RowContent>
              </Row>
            </ContentList>
          </Content>
        </Container>
      )
  );
}
