import styled from 'styled-components';
import { useEffect } from 'react';
import palette from '../../styles/palette';
import LinePlot from './LinePlot';
import useWeeklyData from '../../hooks/useWeeklyData';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.div`
  display: flex;
  padding: 4px 24px;
  color: ${palette.textBlack};
  font-size: 18px;
  font-weight: 700;
`;

export default function WeeklyData({ menu }: { menu: string; }) {
  const {
    weeklyData,
    thisWeekData,
    thisWeekIncreasement,
    unit,
    loadWeeklyData,
  } = useWeeklyData();

  useEffect(() => {
    loadWeeklyData(menu);
  }, [loadWeeklyData, menu]);

  return (
    <Content>
      <ContentTitle>
        {menu}
        :
        {' '}
        {thisWeekData}
        {unit[menu]}
        {' '}
        | 증가율:
        {' '}
        {thisWeekIncreasement}
        %
      </ContentTitle>
      <LinePlot data={weeklyData} />
    </Content>
  );
}
