import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useConsultations from '../../useConsultations';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;

  padding-block: 0.5em;

  h2 {
    margin: 0;
  }
`;

const Search = styled.div`
  display: flex;
  gap: 4px;
  padding: 0 8px;
`;

const InputBox = styled.div`
  width: fit-content;
`;

type QueryType = 'aka' | 'content' | null;

export default function ConsultationsHead() {
  const [queryType, setQueryType] = useState<QueryType>(null);
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();

  const { loadConsultations, initializeConsultations } = useConsultations();

  const initializeQuery = () => {
    setQueryType(null);
    setQuery('');
  };

  const search = () => {
    if (!queryType || !query) {
      window.alert('검색 타입 혹은 검색어를 입력해주세요!!!');
      return;
    }

    initializeQuery();
    navigate(`/?queryType=${queryType}&query=${query}`);
  };

  const refreshSearch = () => {
    initializeQuery();
    navigate('/');
  };

  const refreshData = () => {
    refreshSearch();
    initializeConsultations();
    loadConsultations();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };
  return (
    <Container>
      <Search>
        <InputBox className="input-group">
          <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {queryType || '검색 타입'}
          </button>
          <ul className="dropdown-menu">
            <li>
              <button className="dropdown-item" type="button" onClick={() => setQueryType('aka')}>AKA</button>
            </li>
            <li>
              <button className="dropdown-item" type="button" onClick={() => setQueryType('content')}>내용</button>
            </li>
          </ul>
          <input type="text" value={query} onChange={handleChange} className="form-control" aria-label="Text input with dropdown button" />
        </InputBox>
        <button type="button" className="btn btn-primary" onClick={search}>
          검색
        </button>
        <button type="button" className="btn btn-success" onClick={refreshSearch}>
          검색 초기화
        </button>
        <button type="button" className="btn btn-danger" onClick={refreshData}>
          데이터 최신화
        </button>
      </Search>
    </Container>
  );
}
