import { useCallback, useState } from 'react';
import fetchConversion from './fetchConversion';
import useConsultations from './useConsultations';

export default function useConversion() {
  const { consultations } = useConsultations();
  const [conversion, setConversion] = useState({
    filteredGuestCount: 0,
    totalGuestCount: 0,
    totalUserCount: 0,
    totalConsultationCount: 0,
    inquiryGuestCount: 0,
    inquiryUserCount: 0,
    criminalPossibilityInquiryCount: 0,
    legalPossibilityInquiryCount: 0,
    legalMethodInquiryCount: 0,
    legalValidityInquiryCount: 0,
    proceduralInquiryCount: 0,
    otherInquiryCount: 0,
    nothingInquiryCount: 0,
    criminalPossibilityInquiryRatio: 0,
    legalPossibilityInquiryRatio: 0,
    legalMethodInquiryRatio: 0,
    legalValidityInquiryRatio: 0,
    proceduralInquiryRatio: 0,
    otherInquiryRatio: 0,
  });

  const loadConversion = useCallback(async () => {
    const data = await fetchConversion();

    setConversion(data);
  }, []);

  const {
    filteredGuestCount,
    totalGuestCount,
    totalUserCount,
    totalConsultationCount,
    inquiryGuestCount,
    inquiryUserCount,
    criminalPossibilityInquiryCount,
    criminalPossibilityInquiryRatio,
    legalPossibilityInquiryCount,
    legalPossibilityInquiryRatio,
    legalMethodInquiryCount,
    legalMethodInquiryRatio,
    legalValidityInquiryCount,
    legalValidityInquiryRatio,
    proceduralInquiryCount,
    proceduralInquiryRatio,
    otherInquiryCount,
    otherInquiryRatio,
    nothingInquiryCount,
  } = conversion;
  const inquiryConversion = ((inquiryGuestCount / filteredGuestCount) * 100).toFixed(2);
  const signupConversion = ((inquiryUserCount / inquiryGuestCount) * 100).toFixed(2);

  const totalInquiryCount = consultations.length;
  const longInquiryCount = consultations.filter((c) => c.inquiryLength >= 150).length;

  return {
    loadConversion,
    filteredGuestCount,
    totalGuestCount,
    totalUserCount,
    totalConsultationCount,
    totalInquiryCount,
    longInquiryCount,
    inquiryGuestCount,
    inquiryUserCount,
    inquiryConversion,
    signupConversion,
    criminalPossibilityInquiryCount,
    criminalPossibilityInquiryRatio,
    legalPossibilityInquiryCount,
    legalPossibilityInquiryRatio,
    legalMethodInquiryCount,
    legalMethodInquiryRatio,
    legalValidityInquiryCount,
    legalValidityInquiryRatio,
    proceduralInquiryCount,
    proceduralInquiryRatio,
    otherInquiryCount,
    otherInquiryRatio,
    nothingInquiryCount,
  };
}
