import { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import WeeklyData from '../../types/WeeklyData';

export default function LinePlot({ data }: { data: WeeklyData[] }) {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const margin = {
      top: 20, right: 30, bottom: 20, left: 40,
    };
    const width = 1300 - margin.left - margin.right;
    const height = 330 - margin.top - margin.bottom;

    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3.select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map((d) => d.week))
      .range([0, width])
      .padding(0.1);

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height, 0]);

    svg.append('g')
      .call(d3.axisLeft(y));

    const line = d3.line()
      .x((d) => x(d.week) + x.bandwidth() / 2)
      .y((d) => y(d.value));

    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#092C86')
      .attr('stroke-width', 2)
      .attr('d', line);

    svg.selectAll('dot')
      .data(data)
      .enter().append('circle')
      .attr('cx', (d) => x(d.week) + x.bandwidth() / 2)
      .attr('cy', (d) => y(d.value))
      .attr('r', 4)
      .attr('fill', '#092C86');

    svg.selectAll('text.label')
      .data(data)
      .enter().append('text')
      .attr('class', 'label')
      .attr('x', (d) => x(d.week) + x.bandwidth() / 2)
      .attr('y', (d) => y(d.value) - 10)
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('fill', '#092C86')
      .text((d) => d.value);
  }, [data]);

  return <svg ref={svgRef} />;
}
