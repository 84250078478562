import { useEffect } from 'react';
import styled from 'styled-components';
import useConsultations from '../useConsultations';
import Dashboard from '../Dashboard';
import Consultations from '../Consultations';

const Container = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
padding: 16px;
  
  h1 {
    font-size: 32px;
    padding-left: 8px;
    font-weight: 700;
  }
`;

export default function DashboardPage() {
  const {
    consultations,
    loadConsultations,
    isInquiryLengthAscend,
    isQuestionCountAscend,
    toggleInquiryLengthOrder,
    toggleQuestionCountOrder,
  } = useConsultations();

  useEffect(() => {
    loadConsultations();
  }, [loadConsultations]);

  return (
    <Container>
      <h1>
        노크 어드민
      </h1>
      <Dashboard />
      <Consultations
        consultations={consultations}
        isInquiryLengthAscend={isInquiryLengthAscend}
        isQuestionCountAscend={isQuestionCountAscend}
        toggleInquiryLengthOrder={toggleInquiryLengthOrder}
        toggleQuestionCountOrder={toggleQuestionCountOrder}
      />
    </Container>
  );
}
